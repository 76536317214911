import React, { useEffect, useState } from 'react';
import { ApiCall } from '../config/axios';
import { shop } from '../config/config';

const Product = (allsettings) => {
    const settings = allsettings?.allsettings;
    const genralSettings = settings?.genralSettings;
    const proavailsettings = settings?.proavailsettings;
    const productsettings = settings?.productsettings;
    let productForm = ((genralSettings?.theme_selector !== '') ? `${genralSettings?.theme_selector}, form[action*="/cart/add"].form` : 'form[action*="/cart/add"].form');
    productForm = document.querySelectorAll(productForm);

    function modelhtml() {
        if (!document.getElementById('backModal')) {
            let modalhtml = `<div id="backModal" class="modal" style="display:block">
                <div class="modal-content">
                <span class="close">&times;</span>`;
            const signuphtml = signUpfromHtml();
            const form_style = signuphtml?.form_style;
            modalhtml += signuphtml?.html;
            modalhtml += `</div>
            </div>
            <style>${form_style}</style>`;
            const rootdiv = document.getElementById('backinstockRoot');
            rootdiv.innerHTML += modalhtml;
        } else {
            document.getElementById("backModal").style.display = "block";
        }
        document.getElementsByClassName('close')[0].addEventListener("click", function () {
            document.getElementById("backModal").style.display = "none";
        });
        if (document.getElementsByClassName('stockBtn').length > 0) {
            document.getElementsByClassName('stockBtn')[0].addEventListener("click", submitNotofication);
        }
    }

    const signUpfromHtml = () => {
        let html = ``;
        let form_style = '';
        form_style += `
                .backinstock-main{
                    border: ${productsettings?.form_border_width}px solid ${productsettings?.form_border_color};
                    background-color: ${productsettings?.form_bg_color};
                }
                .backinstockDiv .backinstock-main{
                    color: ${productsettings?.form_text_color};
                }
                .backinstock-title h3{
                    color: ${productsettings?.form_text_color};
                }
                .backinstock-submit-btn{
                    background-color: ${productsettings?.form_button_bg_color};
                    color: ${productsettings?.form_button_text_color};
                    border: ${productsettings?.form_button_border_width}px solid;
                }
                .backinstock-emailaddress .backinstock-email-input{
                    border-radius: ${productsettings?.form_input_border_radius}px;
                    border: ${productsettings?.form_input_border_width}px solid ${productsettings?.form_input_border_color};
                }
                .backinstock-email-error{
                    background-color: ${productsettings?.form_error_bg_color};
                    color: ${productsettings?.form_error_text_color};
                }
                #backinstock-success-msg{
                    background-color: ${productsettings?.form_success_bg_color};
                    color: ${productsettings?.form_success_text_color};
                }
            `;
        html += `<div class="backinstockDiv">
                    <div class="backinstock-main">
                        <div class="backinstock-title">
                            <h3>${productsettings?.form_header_text}</h3>
                        </div>
                        <div class="backinstock-description">${productsettings?.form_body_text}</div>
                        <div class="backinstock-data">
                                <div id="backinstock-form-container">
                                    <div>
                                        <div class="backinstock-newstock">Please notify me as soon as new stock arrives</div>
                                        <div class="newstockmain-div">`;
        if (productsettings?.product_ava_style === '0') {
            proavailsettings.map((item, i) => {
                html += `<div class="newstock_div">
                    <input type="radio" checked name="newstock_radio" id="newstock_${i}" value="${item?.pro_available_number} ${item?.pro_available_option}">
                    <label for="newstock_${i}">Within ${item?.pro_available_number} ${item?.pro_available_option} from today</label>
                </div>`;
            });
        } else {
            html += `<div class="select-box">
                <select id="newstock_select">`;
            proavailsettings.map((item, i) => {
                html += `<option value="${item?.pro_available_number} ${item?.pro_available_option}">Within ${item?.pro_available_number} ${item?.pro_available_option} from today</option>`;
            });
            html += `</select>
            </div>`;
        }
        html += `</div>
                            </div>
                            <div class="backstock-chk">
                                <input type="checkbox" name="custaccepts_marketing" id="custaccepts_marketing">
                                <label for="custaccepts_marketing">Signup for newsletter?</label>
                            </div>
                            <div class="form-group backinstock-emailaddress">
                                <input id="backStockEmail" class="backinstock-email-input" type="email" name="backinstock-email" placeholder='${productsettings?.form_email_address_label}' value="">
                                <div class="backinstock-email-error"></div>
                            </div>
                            <button type="button" class="stockBtn btn button backinstock-submit-btn">${productsettings?.form_button_label}</button>
                        </div>
                    <div id="backinstock-success-msg"></div>
                </div>
                <div class="backinstock-footer">
                    <div id="backinstock-footer-note">${productsettings?.form_footer_text}</div>
                </div>
                <div class="divider"></div>
                <div class="backinstock-footerdiv">Powered by Back In Stock</div>
            </div>
        </div>`;
        return { "html": html, "form_style": form_style };
    }

    const addHtml = (varintid) => {
        const theme_placement = genralSettings?.theme_placement;
        let html = ``;
        let form_style = '';
        if (productsettings?.custom_style === '0') {
            const signuphtml = signUpfromHtml();
            form_style += signuphtml?.form_style;
            html += signuphtml?.html;
        } else {
            form_style += `
                .backinstock-popup-btn{
                    font-size: ${productsettings?.modal_text_size}px;
                    ${(productsettings?.modal_text_style === "italic") ? `font-style: ${productsettings?.modal_text_style}` : ''};
                    ${(productsettings?.modal_text_style === "normal" || productsettings?.modal_text_style === "bold") ? `font-weight: ${productsettings?.modal_text_style}` : ''};
                    ${(productsettings?.modal_text_style === "underline") ? `text-decoration: ${productsettings?.modal_text_style}` : ''};
                    width: ${productsettings?.modal_btn_width}%;
                    height: ${productsettings?.modal_button_height}px;
                    margin: ${productsettings?.modal_margin_top}px ${productsettings?.modal_margin_right}px ${productsettings?.modal_margin_bottom}px ${productsettings?.modal_margin_left}px;
                    border: ${productsettings?.modal_border_size}px solid ${productsettings?.btn_border_color};
                    border-radius: ${productsettings?.modal_border_radius}px !important;
                    background-color: ${productsettings?.btn_bg_color};
                    color: ${productsettings?.btn_text_color};
                }
                .backinstock-popup-btn:after{
                    border-radius: ${productsettings?.modal_border_radius}px !important;
                }
                .backinstock-popup-btn:hover{
                    border: ${productsettings?.modal_border_size}px solid ${productsettings?.btn_hover_border_color};
                    background-color: ${productsettings?.btn_hover_bg_color};
                    color: ${productsettings?.btn_hover_text_color};
                }
                .backinstock-popup-btn.button:not([disabled]):hover:after{
                    box-shadow: none !important;
                }
            `;
            html += `<div class="backinstockDiv">
                <button class="btn button backinstock-popup-btn" id="backinstockBtn">${productsettings?.modal_button_caption}</button>
            </div>`;
        }
        html += `<style>
            ${form_style}
            ${genralSettings?.custom_css}
        </style>`;
        let currVarint_id = document.getElementsByClassName('product-variant-id')[0].value;
        let qty = window.proConfig.variants[currVarint_id]
        if (varintid !== undefined) {
            currVarint_id = varintid;
            qty = window.proConfig.variants[currVarint_id];
        }
        if (theme_placement === "before" && qty === 0 && productForm.length > 0 && document.getElementsByClassName('backinstockDiv').length === 0) {
            productForm[0].insertAdjacentHTML("beforebegin", html);
        } else if (productForm.length > 0 && qty === 0 && document.getElementsByClassName('backinstockDiv').length === 0) {
            productForm[0].insertAdjacentHTML("afterend", html);
        } else if (qty > 0 && document.getElementsByClassName('backinstockDiv').length > 0) {
            document.getElementsByClassName('backinstockDiv')[0].remove();
        }

        if (productsettings?.custom_style === '1') {
            if (document.getElementById('backinstockBtn')) {
                document.getElementById('backinstockBtn').addEventListener("click", modelhtml);
            }
        } else if (document.getElementsByClassName('stockBtn').length) {
            document.getElementsByClassName('stockBtn')[0].addEventListener("click", submitNotofication);
        }
    }

    useEffect(() => {
        var firstTimeUrl = document.URL;
        document.addEventListener('change', function () {
            var currentPageUrl = document.URL;
            var url = new URL(currentPageUrl);
            var isVariantUrl = url.searchParams.get("variant");
            currentPageUrl = isVariantUrl ? currentPageUrl : isVariantUrl;
            if (currentPageUrl && firstTimeUrl != currentPageUrl) {
                firstTimeUrl = currentPageUrl;
                addHtml(isVariantUrl);
            }
        });
        addHtml();
    }, [productForm])

    const submitNotofication = async () => {
        const product_data = window.meta.product;
        const email_input = document.getElementById('backStockEmail').value;
        let newstock = '';
        if (document.querySelectorAll("input[name=newstock_radio]:checked").length > 0) {
            newstock = document.querySelectorAll("input[name=newstock_radio]:checked")[0].value;
        }
        if (document.getElementById("newstock_select")) {
            newstock = document.getElementById("newstock_select").value;
        }
        let cust_marketing = 0;
        if (document.querySelector('#custaccepts_marketing').checked) {
            cust_marketing = 1;
        }
        if (validateEmail(email_input)) {
            const currVarint_id = document.getElementsByClassName('product-variant-id')[0].value;
            const qty = window.proConfig.variants[currVarint_id];
            document.getElementsByClassName('backinstock-email-error')[0].style.display = "none";
            const metavari = window.meta.product.variants;
            let obj = metavari.find(o => o.id == currVarint_id);
            const data = {
                shop: shop,
                product_no: product_data.id,
                product_title: window.proConfig.product.title,
                product_vendor: window.proConfig.product.vendor,
                product_handle: window.proConfig.product.handle,
                variant_no: currVarint_id,
                variant_title: obj.public_title,
                sku: obj.sku,
                qty: qty,
                email: email_input,
                newstock: newstock,
                cust_marketing: cust_marketing
            };
            const res = await ApiCall('POST', '/back-in-stock', data);
            if (res.data.data.msg === "success") {
                document.getElementById('backinstock-success-msg').innerHTML = productsettings?.registration_complete;
                setTimeout(() => {
                    document.getElementById('backinstock-success-msg').innerHTML = '';
                }, 20000);
            }
        } else {
            if (email_input === '') {
                document.getElementsByClassName('backinstock-email-error')[0].innerHTML = productsettings?.provide_email_phone;
            } else {
                document.getElementsByClassName('backinstock-email-error')[0].innerHTML = productsettings?.invalid_email_message;
            }
            document.getElementsByClassName('backinstock-email-error')[0].style.display = "block";
        }
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    return (<></>);
}

export default Product;
